import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const RulesRegulations = () => {
    const { t } = useTranslation();

    useEffect(() => {
        let hash = window.location.hash;
        let anchorElement = document.getElementById(hash.split('#')[1]);
        if (anchorElement) {
            window.setTimeout(anchorElement.scrollIntoView(true), 0);
        }
    }, []);

    return (
        <>
            <div className="p-3 rules-wrapper">
                <div className="rule-wrapper">
                    <strong class="text-yellow">{t('MEMBER_TRANSFER')}</strong>
                    <ul>
                        <li>{t("RULE_TRANSFER_1")}</li>
                        <li>{t("RULE_TRANSFER_2")}</li>
                        <li>{t("RULE_TRANSFER_3")}
                            <ul>
                                <li>{t("RULE_TRANSFER_3_1")}</li>
                                <li>{t("RULE_TRANSFER_3_2")}</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="rule-wrapper">
                    <strong class="text-yellow">{t('TRANSFER_RULES')}</strong>
                    <ul>
                        <li>{t("RULE_TRANSFER_4")}</li>
                        <li>{t("RULE_TRANSFER_5")}</li>
                        <li>{t("RULE_TRANSFER_6")}</li>
                    </ul>
                </div>
                <div className="rule-wrapper">
                    <strong class="text-yellow">{t('MEMBER_REFERRAL_PROGRAM')}</strong>
                    <ul>
                        <li>{t("RULE_REFERRAL_1")}</li>
                        <li>{t("RULE_REFERRAL_2")}
                            <ul>
                                <li>{t("RULE_REFERRAL_2_1")}</li>
                                <li>{t("RULE_REFERRAL_2_2")}</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="rule-wrapper">
                    <strong class="text-yellow">{t('MEMBER_REFERRAL_PROGRAM_RULES')}</strong>
                    <ul>
                        <li>{t("RULE_REFERRAL_3")}</li>
                        <li>{t("RULE_REFERRAL_4")}</li>
                    </ul>
                </div>
                <div id="rank" className="rule-wrapper">
                    <strong class="text-yellow">{t('MEMBER_EXPERIENCE')}</strong>
                    <ul>
                        <li>{t("RULE_EXP_1")}</li>
                        <li>{t("RULE_EXP_2")}
                            <ul>
                                <li>{t("RULE_EXP_2_1")}</li>
                                <li>{t("RULE_EXP_2_2")}</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="rule-wrapper">
                    <strong class="text-yellow">{t('MEMBER_EXPERIENCE_RULES')}</strong>
                    <ul>
                        <li>{t("RULE_EXP_3")}</li>
                        <li>{t("RULE_EXP_4")}</li>
                    </ul>
                </div>
            </div>
            <hr className="brand-line" />
            <div className="bg-gold-gradient golden-notice" style={{ margin: '1rem 1rem 1rem 1rem' }}>
                <div className="golden-notice-content">
                    <div className="d-flex align-items-center text-yellow">{t('RULES_COMPANY_DECISION')}</div>
                </div>
            </div>
        </>
    );
};

export default RulesRegulations;