import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import EarnExp from 'assets/img/rank-earn-exp.png';
import DropExp from 'assets/img/rank-drop-exp.png';
import DropRank from 'assets/img/rank-drop-rank.png';
import AppRoutes from 'util/AppRoutes';

const RankingIndex = () => {
    const { t } = useTranslation();

    return (
        <>
            <div class="menu-list pb-0">
                <Link to={AppRoutes.RANKING_GAIN_EXP} className="bg-gold-gradient menu-item">
                    <div class="menu-item-content">
                        <img src={EarnExp} />
                        <div class="menu-item-content-inner">
                            <div class="title">{t("HOW_TO_EARN_EXP")}</div>
                        </div>
                        <i class="las la-caret-right"></i>
                    </div>
                </Link>
                <Link to={AppRoutes.RANKING_DROP_EXP} className="bg-gold-gradient menu-item">
                    <div class="menu-item-content">
                        <img src={DropExp} />
                        <div class="menu-item-content-inner">
                            <div class="title">{t("WHAT_CAUSES_EXP_DROP")}</div>
                        </div>
                        <i class="las la-caret-right"></i>
                    </div>
                </Link>
                <Link to={AppRoutes.RANKING_DROP_RANK} className="bg-gold-gradient menu-item">
                    <div class="menu-item-content">
                        <img src={DropRank} />
                        <div class="menu-item-content-inner">
                            <div class="title">{t("WHAT_CAUSES_RANK_DROP")}</div>
                        </div>
                        <i class="las la-caret-right"></i>
                    </div>
                </Link>
            </div>
            <hr className="brand-line" />
            <Link to={`${AppRoutes.RULES}#rank`} className="bg-gold-gradient golden-notice" style={{ margin: '1rem 1rem 0 1rem' }}>
                <div className="golden-notice-content">
                    <div className="d-flex align-items-center text-yellow">
                        <i className="las la-shapes me-1 fs-3" />
                        <strong>{t('RULES_REGULATIONS')}</strong>
                    </div>
                    <div className="btn-rounded-purple">
                        <i class="las la-angle-right"></i>
                    </div>
                </div>
            </Link>
        </>
    );
};

export default RankingIndex;