import ApiRoutes from "util/ApiRoutes";
import { ApiKey } from "util/Constants";

/// <summary>
/// Author: KurisuCodes
/// </summary>
export async function loginUser(dispatch, loginPayload) {
    try {
        dispatch({ type: "AUTHENTICATING" });

        // Sample API Calling
        // let responseJson = await ApiEngine.post(ApiRoutes.AUTHENTICATE, loginPayload);

        // if (!responseJson[ApiKey.API_SUCCESS_KEY]) {
        //     throw new Error(responseJson[ApiKey.API_MESSAGE_KEY]);
        // }

        localStorage.setItem('user', JSON.stringify(loginPayload));
        dispatch({ type: "LOGIN_SUCCESS", payload: {
            user: loginPayload.username,
            token: "token-1234"
        } });
    }
    catch (error) {
        dispatch({ type: "LOGIN_ERROR", error });
    }
}