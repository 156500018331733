import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReceiveCredit from './ReceiveCredit';
import SendCredit from './SendCredit';

const TransferIndex = () => {
    const { t } = useTranslation();

    return (
        <Tabs defaultActiveKey="send" justify className="brand-nav-tabs">
            <Tab eventKey="send" title={t('SEND_ECREDIT')}>
                <SendCredit />
            </Tab>
            <Tab eventKey="receive" title={t('RECEIVE_ECREDIT')}>
                <ReceiveCredit />
            </Tab>
        </Tabs>
    );
};

export default TransferIndex;