import React from 'react';
import DepositImg from 'assets/img/deposit.png';
import WithdrawImg from 'assets/img/withdraw.png';
import TransferImg from 'assets/img/transfer.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AppRoutes from 'util/AppRoutes';

const TransactionIndex = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="menu-list pb-0">
                <Link to="/transaction/deposit" className="bg-gold-gradient menu-item">
                    <div class="menu-item-content">
                        <img src={DepositImg} />
                        <div class="menu-item-content-inner">
                            <div class="title">{t("HOW_TO_DEPOSIT")}</div>
                        </div>
                        <i class="las la-caret-right"></i>
                    </div>
                </Link>
                <Link to="/transaction/withdraw" className="bg-gold-gradient menu-item">
                    <div class="menu-item-content">
                        <img src={WithdrawImg} />
                        <div class="menu-item-content-inner">
                            <div class="title">{t("HOW_TO_WITHDRAW")}</div>
                        </div>
                        <i class="las la-caret-right"></i>
                    </div>
                </Link>
                <Link to="/transaction/transfer" className="bg-gold-gradient menu-item">
                    <div class="menu-item-content">
                        <img src={TransferImg} />
                        <div class="menu-item-content-inner">
                            <div class="title">{t("HOW_TO_TRANSFER")}</div>
                        </div>
                        <i class="las la-caret-right"></i>
                    </div>
                </Link>
            </div>
            <hr className="brand-line" />
            <Link to={AppRoutes.RULES} className="bg-gold-gradient golden-notice" style={{ margin: '1rem 1rem 0 1rem' }}>
                <div className="golden-notice-content">
                    <div className="d-flex align-items-center text-yellow">
                        <i className="las la-shapes me-1 fs-3" />
                        <strong>{t('RULES_REGULATIONS')}</strong>
                    </div>
                    <div className="btn-rounded-purple">
                        <i class="las la-angle-right"></i>
                    </div>
                </div>
            </Link>
        </>
    );
};

export default TransactionIndex;