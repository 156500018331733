import TutorialCard from 'components/TutorialCard';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DepositStep1 from 'assets/img/deposit-gpay-step1.png';
import DepositStep2 from 'assets/img/deposit-gpay-step2.png';
import DepositStep3 from 'assets/img/deposit-gpay-step3.png';
import DepositStep4 from 'assets/img/deposit-gpay-step4.png';
import DepositStep5 from 'assets/img/deposit-gpay-step5.png';

const OnlineBanking = () => {
    const { t } = useTranslation();

    const steps = [
        {
            text: t('DEPOSIT_STEP_1'),
            img: DepositStep1
        },
        {
            text: t('DEPOSIT_STEP_2'),
            img: DepositStep2
        },
        {
            text: t('DEPOSIT_STEP_3'),
            img: DepositStep3
        },
        {
            text: t('DEPOSIT_STEP_4'),
            img: DepositStep4
        },
        {
            text: t('DEPOSIT_STEP_5'),
            img: DepositStep5
        }
    ];

    return (
        <div className="tutorial-list">
            {
                steps.map((step, index) => {
                    return <TutorialCard key={index} index={index + 1} {...step} />
                })
            }
        </div>
    );
};

export default OnlineBanking;