import { Route, Switch } from 'react-router-dom';
import routes from './RouteConfiguration';

/// <summary>
/// Author: KurisuCodes
/// </summary>
export default () => (
    <Switch>
        {
            routes.map((route, index) => (
                <Route key={index} {...route} />
            ))
        }
    </Switch>
);