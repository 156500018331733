/// <summary>
/// Author: KurisuCodes
/// API related key or field when making ajax or api call
/// </summary>
export const ApiKey = {
    API_SUCCESS_KEY: "success",
    API_MESSAGE_KEY: "message",
    API_DATA_KEY: "data",

    API_CONTENT_TYPE: 'Content-Type',
    API_ACCEPT: "Accept",
    API_REQUESTED_WITH: "X-Requested-With",
    API_APPLICATION_JSON: "application/json",
    API_XML_HTTP_REQUEST_KEY: "XMLHttpRequest",
    API_FORM_URLENCODED: "application/x-www-form-urlencoded",
    API_FORM_DATA: "multipart/form-data",
    API_POST: "POST",
    API_GET: "GET",
    API_PUT: "PUT",
    API_DELETE: "DELETE",
}

/// <summary>
/// Author: KurisuCodes
/// </summary>
export const SessionKey = {
    LANGUAGE: "language"
}

/// <summary>
/// Author: KurisuCodes
/// </summary>
export const Language = {
    ENGLISH: "en",
    CHINESE: "zh",
    MALAY: "ms"
}

/// <summary>
/// Author: KurisuCodes
/// </summary>
export const _DEFAULT_API_HEADERS = {
    [ApiKey.API_ACCEPT]: ApiKey.API_APPLICATION_JSON,
    [ApiKey.API_REQUESTED_WITH]: ApiKey.API_XML_HTTP_REQUEST_KEY,
}