import React from 'react';
import { useTranslation } from 'react-i18next';
import TutorialCard from 'components/TutorialCard';

import TransferStep1 from 'assets/img/transfer-epoint-step1.png';
import TransferStep2 from 'assets/img/transfer-epoint-step2.png';
import TransferStep3 from 'assets/img/transfer-epoint-step3.png';
import TransferStep4 from 'assets/img/transfer-epoint-step4.png';
import TransferStep5 from 'assets/img/transfer-epoint-step5.png';
import TransferStep6 from 'assets/img/transfer-epoint-step6.png';

const Transfer = () => {
    const { t } = useTranslation();

    const steps = [
        {
            text: t('TRANSFER_EPOINT_STEP_1'),
            img: TransferStep1
        },
        {
            text: t('TRANSFER_EPOINT_STEP_2'),
            img: TransferStep2
        },
        {
            text: t('TRANSFER_EPOINT_STEP_3'),
            img: TransferStep3
        },
        {
            text: t('TRANSFER_EPOINT_STEP_4'),
            img: TransferStep4
        },
        {
            text: t('TRANSFER_EPOINT_STEP_5'),
            img: TransferStep5
        },
        {
            text: t('TRANSFER_EPOINT_STEP_6'),
            img: TransferStep6
        }
    ]

    return (
        <div className="tutorial-list">
            {
                steps.map((step, index) => {
                    return <TutorialCard key={index} index={index + 1} {...step} />
                })
            }
        </div>
    );
};

export default Transfer;
