import { AuthProvider } from 'context';
import Router from 'navigation/Router';

// import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/app.scss';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router';
import { SessionKey } from 'util/Constants';

export default () => {
    const { i18n } = useTranslation()

    const useQuery = () => {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();

    useEffect(() => {
        let lang = query.get("lang");
        if (lang) {
            i18n.changeLanguage(lang);
            localStorage.setItem(SessionKey.LANGUAGE, lang);
        }
    }, [query])

    return (
        <AuthProvider>
            <Router />
        </AuthProvider>
    );
}
