import React from 'react';
import { useTranslation } from 'react-i18next';
import TutorialCard from 'components/TutorialCard';

import DropExpStep1 from 'assets/img/ranking-exp-deduction-step1.png';
import DropExpStep2 from 'assets/img/ranking-exp-deduction-step2.png';
import DropExpStep3 from 'assets/img/ranking-exp-deduction-step3.png';
import AppRoutes from 'util/AppRoutes';
import { Link } from 'react-router-dom';

const DropExp = () => {
    const { t } = useTranslation();

    const steps = [
        {
            text: t('DROP_EXP_STEP_1'),
            img: DropExpStep1
        },
        {
            text: t('DROP_EXP_STEP_2'),
            img: DropExpStep2
        },
        {
            text: t('DROP_EXP_STEP_3'),
            img: DropExpStep3
        }
    ]

    return (
        <>
            <div class="text-content">
                { t('DROP_EXP_DESC') }
            </div>
            <hr className="brand-line" />
            <div className="tutorial-list pb-0">
                {
                    steps.map((step, index) => {
                        return <TutorialCard key={index} index={index + 1} {...step} />
                    })
                }
            </div>
            <hr className="brand-line" />
            <Link to={AppRoutes.RANKING_DROP_RANK} className="bg-gold-gradient golden-notice" style={{ margin: '1rem 1rem 0 1rem' }}>
                <div className="golden-notice-content">
                    <div>
                        <span className="text-white">{t('YOU_MAY_WANT_TO_KNOW_NEXT')}</span><br />
                        <strong className="text-yellow">{t('WHAT_CAUSES_RANK_DROP')}</strong>
                    </div>
                    <div className="btn-rounded-purple">
                        <i class="las la-angle-right"></i>
                    </div>
                </div>
            </Link>
        </>
    );
};

export default DropExp;