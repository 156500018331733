import { useReducer } from "react";

let user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).user
    : "";

let token = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).token
    : "";

export const initialState = {
    user: "" || user,
    token: "" || token,
    isLoading: false,
    errorMsg: null
}

export const AuthReducer = (initialState, action) => {
    switch (action.type) {
        case "AUTHENTICATING":
            return {
                ...initialState,
                isLoading: true
            };
        case "LOGIN_SUCCESS":
            alert(action);
            return {
                ...initialState,
                user: action.payload.user,
                token: action.payload.token,
                isLoading: false
            };
        case "LOGIN_ERROR":
            return {
                ...initialState,
                isLoading: false,
                errorMsg: action.errorMsg
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};