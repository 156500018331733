import React from 'react';
import { useTranslation } from 'react-i18next';
import TutorialCard from 'components/TutorialCard';

import ConvertStep1 from 'assets/img/convert-reward-to-ecredit-step1.png';
import ConvertStep2 from 'assets/img/convert-reward-to-ecredit-step2.png';
import ConvertStep3 from 'assets/img/convert-reward-to-ecredit-step3.png';
import ConvertStep4 from 'assets/img/convert-reward-to-ecredit-step4.png';

const Reward = () => {
    const { t } = useTranslation();

    const steps = [
        {
            text: t('CONVERT_REWARD_STEP_1'),
            img: ConvertStep1
        },
        {
            text: t('CONVERT_REWARD_STEP_2'),
            img: ConvertStep2
        },
        {
            text: t('CONVERT_REWARD_STEP_3'),
            img: ConvertStep3
        },
        {
            text: t('CONVERT_REWARD_STEP_4'),
            img: ConvertStep4
        }
    ]

    return (
        <div className="tutorial-list">
            {
                steps.map((step, index) => {
                    return <TutorialCard key={index} index={index + 1} {...step} />
                })
            }
        </div>
    );
};

export default Reward;