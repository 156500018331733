import React from 'react';
import PropTypes from 'prop-types';

const TutorialCard = (props) => {
    return (
        <div className="tutorial-card">
            <img className="tutorial-img" src={props.img} />
            <div className="tutorial-content">
                {
                    props.badge &&
                    <span className="badge-gold">{props.badge}</span>
                }
                <div className="tutorial-wrapper">
                    <span className="number">{props.index}</span>
                    <div className="tutorial-desc">
                        {props.text}
                    </div>
                </div>
            </div>
        </div>
    );
};

TutorialCard.propTypes = {
    text: PropTypes.string
}

export default TutorialCard;