import AppRoutes from 'util/AppRoutes';

import Login from 'pages/login';
import Home from 'pages/home';
import TransactionIndex from 'pages/TransactionIndex';
import DepositIndex from 'pages/deposit/DepositIndex';
import WithdrawIndex from 'pages/withdraw/WithdrawIndex';
import TransferIndex from 'pages/transfer/TransferIndex';
import ConvertIndex from 'pages/ConvertIndex';
import ConvertReward from 'pages/convert/Reward';
import ConvertECreditEPoint from 'pages/convert/ConvertECreditEPoint';
import ConvertTransfer from 'pages/convert/Transfer';
import RankingIndex from 'pages/RankingIndex';
import GainExp from 'pages/ranking/GainExp';
import DropExp from 'pages/ranking/DropExp';
import DropRank from 'pages/ranking/DropRank';
import RulesRegulations from 'pages/home/rules';
import PrivacyPolicy from 'pages/PrivacyPolicy';

export default [
    {
        path: AppRoutes.HOME,
        title: 'Home',
        component: () => <Home />,
        authRequired: true,
        exact: true,
    },
    {
        path: AppRoutes.RULES,
        title: 'Rules & Regulations',
        component: () => <RulesRegulations />,
        authRequired: true,
        exact: true,
    },
    {
        path: AppRoutes.LOGIN,
        title: 'Login',
        component: () => <Login />,
        authRequired: true,
        exact: true,
    },
    {
        path: AppRoutes.DEPOSIT_INDEX,
        title: 'Deposit',
        component: () => <DepositIndex />,
        exact: true,
    },
    {
        path: AppRoutes.TRANSACTION_INDEX,
        title: 'Transaction',
        component: () => <TransactionIndex />,
        exact: true,
    },
    {
        path: AppRoutes.WITHDRAW_INDEX,
        title: 'Withdraw',
        component: () => <WithdrawIndex />,
        exact: true,
    },
    {
        path: AppRoutes.TRANSFER_INDEX,
        title: 'Transfer',
        component: () => <TransferIndex />,
        exact: true,
    },
    {
        path: AppRoutes.CONVERT_INDEX,
        title: 'Convert',
        component: () => <ConvertIndex />,
        exact: true
    },
    {
        path: AppRoutes.CONVERT_REWARD,
        title: 'Convert Reward',
        component: () => <ConvertReward />,
        exact: true
    },
    {
        path: AppRoutes.CONVERT_CONVERTER,
        title: 'Convert e-Credit / e-Point',
        component: () => <ConvertECreditEPoint />,
        exact: true
    },
    {
        path: AppRoutes.CONVERT_TRANSFER,
        title: 'Transfer',
        component: () => <ConvertTransfer />,
        exact: true
    },
    {
        path: AppRoutes.RANKING_INDEX,
        title: 'Ranking',
        component: () => <RankingIndex />,
        exact: true
    },
    {
        path: AppRoutes.RANKING_GAIN_EXP,
        title: 'Gain Exp',
        component: () => <GainExp />,
        exact: true
    },
    {
        path: AppRoutes.RANKING_DROP_EXP,
        title: 'Drop Exp',
        component: () => <DropExp />,
        exact: true
    },
    {
        path: AppRoutes.RANKING_DROP_RANK,
        title: 'Drop Rank',
        component: () => <DropRank />,
        exact: true
    },
    {
        path: AppRoutes.PRIVACY_POLICY,
        title: 'Privacy Policy',
        component: () => <PrivacyPolicy />,
        exact: true
    }
];