import React from 'react';
import { useTranslation } from 'react-i18next';
import TutorialCard from 'components/TutorialCard';

import WithdrawStep1 from 'assets/img/withdraw-ecredit-step1.png';
import WithdrawStep2 from 'assets/img/withdraw-ecredit-step2.png';
import WithdrawStep3 from 'assets/img/withdraw-ecredit-step3.png';
import WithdrawStep4 from 'assets/img/withdraw-ecredit-step4.png';
import WithdrawStep4_SubmitReq from 'assets/img/withdraw-ecredit-step4-option1.png';
import WithdrawStep4_QR from 'assets/img/withdraw-ecredit-step4-option2.png';
import WithdrawStep5 from 'assets/img/withdraw-ecredit-step5.png';
import WithdrawStep6 from 'assets/img/withdraw-ecredit-step6.png';

const ECredit = () => {
    const { t } = useTranslation();

    const steps = [
        {
            text: t('WITHDRAW_CREDIT_STEP_1'),
            img: WithdrawStep1
        },
        {
            text: t('WITHDRAW_CREDIT_STEP_2'),
            img: WithdrawStep2
        },
        {
            text: t('WITHDRAW_CREDIT_STEP_3'),
            img: WithdrawStep3
        },
        {
            text: t('WITHDRAW_CREDIT_STEP_4'),
            img: WithdrawStep4
        },
        {
            text: t('WITHDRAW_CREDIT_STEP_4_SUBMIT_REQ'),
            badge: t('SUBMIT_REQUEST'),
            img: WithdrawStep4_SubmitReq
        },
        {
            text: t('WITHDRAW_CREDIT_STEP_4_QR_CODE'),
            badge: t('GENERATE_QR'),
            img: WithdrawStep4_QR
        },
        {
            text: t('WITHDRAW_CREDIT_STEP_5'),
            img: WithdrawStep5
        },
        {
            text: t('WITHDRAW_CREDIT_STEP_6'),
            img: WithdrawStep6
        }
    ]

    return (
        <div className="tutorial-list">
            {
                steps.map((step, index) => {
                    return <TutorialCard key={index} index={index + 1} {...step} />
                })
            }
        </div>
    );
};

export default ECredit;