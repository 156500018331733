import { useForm } from "react-hook-form";
import { loginUser, useAuthDispatch } from "context";
import AppRoutes from "util/AppRoutes";
import { useHistory } from "react-router";

/// <summary>
/// Author: KurisuCodes
/// </summary>
export default () => {
    const history = useHistory();
    const dispatch = useAuthDispatch();
    const { register, handleSubmit } = useForm({
        defaultValues: {
            username: '',
            password: '',
        }
    });

    /// <summary>
    /// Author: KurisuCodes
    /// </summary>
    async function onSubmit(data) {
        try {
            loginUser(dispatch, data);
            history.push(AppRoutes.HOME);
        }
        catch (error) {
            alert(error);
        }
    }

    return (
        <div>
            <h1>ReactJS BoilerPlate</h1>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label htmlFor="username">Username</label>
                    <input id="username" type="text" {...register('username')} />
                </div>
                <div>
                    <label htmlFor="password">Password</label>
                    <input id="password" type="password" {...register('password')} />
                </div>

                <button>Login</button>
            </form>
        </div>
    );
}