import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import TransactionHelp from 'assets/img/transaction-help.png';
import RankingHelp from 'assets/img/ranking-help.png';
import ConvertHelp from 'assets/img/convert-help.png';
import AppRoutes from 'util/AppRoutes';

/// <summary>
/// Author: KurisuCodes
/// </summary>
export default () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="menu-list pb-0">
                <Link to="/transaction" className="bg-gold-gradient menu-item">
                    <div class="menu-item-content">
                        <img src={TransactionHelp} />
                        <div class="menu-item-content-inner">
                            <div class="title">{t("TRANSACTION")}</div>
                            <div class="desc">{t("TRANSACTION_DESC")}</div>
                        </div>
                        <i class="las la-caret-right"></i>
                    </div>
                </Link>
                <Link to="/convert" className="bg-gold-gradient menu-item">
                    <div class="menu-item-content">
                        <img src={ConvertHelp} />
                        <div class="menu-item-content-inner">
                            <div class="title">{t("CONVERT")}</div>
                            <div class="desc">{t("CONVERT_DESC")}</div>
                        </div>
                        <i class="las la-caret-right"></i>
                    </div>
                </Link>
                <Link to="/ranking" className="bg-gold-gradient menu-item">
                    <div class="menu-item-content">
                        <img src={RankingHelp} />
                        <div class="menu-item-content-inner">
                            <div class="title">
                                {t("RANKING")}
                                <span class="badge-beta">BETA</span>
                            </div>
                            <div class="desc">{t("RANKING_DESC")}</div>
                        </div>
                        <i class="las la-caret-right"></i>
                    </div>
                </Link>
            </div>
            <hr className="brand-line" />
            <Link to={AppRoutes.RULES} className="bg-gold-gradient golden-notice" style={{ margin: '1rem 1rem 0 1rem' }}>
                <div className="golden-notice-content">
                    <div className="d-flex align-items-center text-yellow">
                        <i className="las la-shapes me-1 fs-3" />
                        <strong>{t('RULES_REGULATIONS')}</strong>
                    </div>
                    <div className="btn-rounded-purple">
                        <i class="las la-angle-right"></i>
                    </div>
                </div>
            </Link>
        </>
    );
}