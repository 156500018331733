import React from 'react';
import { useTranslation } from 'react-i18next';
import TutorialCard from 'components/TutorialCard';

import GainExpStep0 from 'assets/img/ranking-gain-exp-step0.png';
import GainExpStep1 from 'assets/img/ranking-gain-exp-step1.png';
import GainExpStep2 from 'assets/img/ranking-gain-exp-step2.png';

const GainExp = () => {
    const { t } = useTranslation();

    const steps = [
        {
            text: t('GAIN_EXP_STEP_1'),
            img: GainExpStep0
        },
        {
            text: t('GAIN_EXP_STEP_2'),
            img: GainExpStep1
        },
        {
            text: t('GAIN_EXP_STEP_3'),
            img: GainExpStep2
        }
    ]

    return (
        <div className="tutorial-list pb-0">
            {
                steps.map((step, index) => {
                    return <TutorialCard key={index} index={index + 1} {...step} />
                })
            }
        </div>
    );
};

export default GainExp;