import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ConvertReward from 'assets/img/convert-reward.png';
import ConvertWallet from 'assets/img/convert-wallet.png';
import ConvertTransfer from 'assets/img/convert-transfer.png';
import AppRoutes from 'util/AppRoutes';

const ConvertIndex = () => {
    const { t } = useTranslation();

    return (
        <>
            <div class="menu-list pb-0">
                <Link to="/convert/reward" className="bg-gold-gradient menu-item">
                    <div class="menu-item-content">
                        <img src={ConvertReward} />
                        <div class="menu-item-content-inner">
                            <div class="title">{t("HOW_TO_CONVERT_REWARD_TO_WALLET")}</div>
                        </div>
                        <i class="las la-caret-right"></i>
                    </div>
                </Link>
                <Link to="/convert/converter" className="bg-gold-gradient menu-item">
                    <div class="menu-item-content">
                        <img src={ConvertWallet} />
                        <div class="menu-item-content-inner">
                            <div class="title">{t("HOW_TO_CONVERT_WALLET_SCORE")}</div>
                        </div>
                        <i class="las la-caret-right"></i>
                    </div>
                </Link>
                <Link to="/convert/transfer" className="bg-gold-gradient menu-item">
                    <div class="menu-item-content">
                        <img src={ConvertTransfer} />
                        <div class="menu-item-content-inner">
                            <div class="title">{t("HOW_TO_TRANSFER_SCORE")}</div>
                        </div>
                        <i class="las la-caret-right"></i>
                    </div>
                </Link>
            </div>
            <hr className="brand-line" />
            <Link to={AppRoutes.RULES} className="bg-gold-gradient golden-notice" style={{ margin: '1rem 1rem 0 1rem' }}>
                <div className="golden-notice-content">
                    <div className="d-flex align-items-center text-yellow">
                        <i className="las la-shapes me-1 fs-3" />
                        <strong>{t('RULES_REGULATIONS')}</strong>
                    </div>
                    <div className="btn-rounded-purple">
                        <i class="las la-angle-right"></i>
                    </div>
                </div>
            </Link>
        </>
    );
};

export default ConvertIndex;